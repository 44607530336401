<template>
	<el-dialog title="工程进度调整" v-model="centerDialogVisiblegl" width="40%" center :close-on-click-modal = "false" @close='closecallinfo'>
		<div class="ctms_questShowM">
			<div class="ctms_questTableDiv" id="main" style="width: 1200px;height: 400px;max-height: 550px;">
			<!-- <table class="ctms_questTable" >
				<tbody >
					<tr v-for="progress2 in progress" :key="progress2.user">
						<td>{{progress2.user}}</td>
						<td>{{progress2.createTime}}</td>
						<td><span class="spanArr" v-if="progress2.stat==0">{{progress2.gcjd}}</span>
						<span class="spanArr arrUp" v-if="progress2.stat==1">{{progress2.gcjd}}</span></td>
					</tr>
				</tbody>
			</table> -->
			</div>
		</div>
	</el-dialog>
	
</template>

<script>
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	import * as echarts from 'echarts';
	import 'echarts/lib/component/tooltip';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data:function() {
			this.charts=null
			return{
				id: this.$parent.editId,
				centerDialogVisiblegl:true,
					
			}
		},
		created:function(){
			this.searchUpdateList();
		},
		methods:{
			closecallinfo: function() {
				this.centerDialogVisiblegl = true;
				this.$emit("closecallinfo");
				// this.searchUpdateList();
				if (!this.charts) {
					return
				}
				// window.removeEventListener('resize', this.__resizeHandler)
				this.charts.dispose()
				this.charts = null
			},
			searchUpdateList:function(){
				this.progress = [];
				this.option = [];
				this.optionData = [];
				var req = {};
				req.cmd = "searchUpdateList";
				req.id = this.id
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result);
					this.progress = res.datas.result;
					console.log(this.progress.length);
					if(this.progress.length>0){
						this.percentage = res.datas.result[this.progress.length-1].gcjd;
						for(var i=0;i<this.progress.length;i++){
							this.option.push(this.progress[i].createTime);
							this.optionData.push(this.progress[i].gcjd);
						}
					}else{
						this.percentage = 0;
					}
					this.drawcategory('main');
				}, null, this);	
			},
			drawcategory:function(id){
				this.charts=echarts.init(document.getElementById(id))
				var self=this;
				this.charts.setOption({
					tooltip: {
					    trigger: 'axis',
						formatter:function(params){
							var res = '<div><p>时间：'+params[0].name+'</p></div>';
							for(var i=0;i<self.progress.length;i++){
								if(self.progress[i].createTime==params[0].name){
									res+='<p>'+self.progress[i].user+':'+self.progress[i].gcjd+'%'+'</p>'
								}
							}
							return res;
						}
					},
					xAxis: {
						type: 'category',
						data: this.option
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: '{value} %'
						}
					},
					series: [
						{
							data: this.optionData,
							type: 'line'
						}
					]
				})
			},
		},
		
		
	}
	
</script>

<style>
</style>
