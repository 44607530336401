<template>
	<div class="ucPanel pageTname">工程进度调整统计</div>
	<div class="ucPanel bgGrayBox ctms_srh ucBtn-bg" :class="{'ctms_srhHide' : shouldshow }">  <!-- 收起 添加 ctms_srhHide -->
		<div class="ctms_srhR_arr" @click="putaway">{{putawayname}}</div>
		<div class="ucForm ucForm-flow">
		    <div class="ucFrom-row">
		    	<div class="ucForm-group">
		    		<label class="ucForm-label">开始</label>
		    		<el-config-provider :locale="locale">
		    		<el-date-picker v-model="beginDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker>
		    		<label class="ucForm-label">截止</label>
		    		<el-date-picker v-model="endDate" type="date" value-format="YYYY-MM-DD" ></el-date-picker>
		    		</el-config-provider>
		    	</div>
				<div class="ucForm-group">
					<label  class="ucForm-label">合同编号</label>
					<input name="cno" v-model="cno" type="text" autocomplete="off" class="ucForm-control" placeholder="请输入" />
				</div>
		    </div>
			
		    <div class="ucFrom-row">
			
		    </div>
			<div class="ucFrom-row">
			<div class="ucForm-group" style="width:100%">
				<label class="ucForm-label">签订部门</label>
				<el-select v-model="deptId" multiple class="elSelectFull">
					<el-option v-for="option in departments" :key="option.id" :label="option.name" :value="option.id">
						{{option.name}}
					</el-option>
				</el-select>
			</div>
			</div>
			<div class="clear"></div>
		</div>
	    <div class="ctms_srhBtn">
			<button class="ucBtn ucBtn-search ucBtn-green"  @click="handleClickQuery()"><i class="iImg"></i>查询</button>
			<button class="ucBtn ucBtn-import ucBtn-green"  @click="handleClickExport()"><i class="iImg"></i>导出</button>
			<!-- <button class="ucBtn ucBtn-export ucBtn-green" @click="handleClickImport()"><i class="iImg"></i>导入合同列表</button> -->
		</div>
	</div>
	
	
	
	
	<div class="ucPanel ucPanel-table">
		
			<el-table :data="dataList" highlight-current-row border style="width: 100%" :header-cell-style="{textAlign: 'center'}">
				<!-- <el-table-column prop="id" label="序号"  width="78px;"></el-table-column> -->
				<el-table-column label="序号" width="78px;">
					<template v-slot="scope">
						{{ scope.$index+1 }}
					</template>
				</el-table-column>
				<el-table-column prop="department" label="部门" ></el-table-column>
				<el-table-column label="合同基本信息" style="width:100px">
				<el-table-column prop="cno" label="合同编号" ></el-table-column>
				<el-table-column prop="cname" label="合同名称" ></el-table-column>
				</el-table-column>
				<el-table-column label="本期" style="width:100px">
				<el-table-column prop="thisCreateTime" label="调整时间" ></el-table-column>
				<el-table-column prop="thisGcjd" label="进度值(%)" ></el-table-column>
				</el-table-column>
				<el-table-column label="上期" style="width:100px">
				<el-table-column prop="lastCreateTime" label="调整时间" ></el-table-column>
				<el-table-column prop="lastGcjd" label="进度值(%)" ></el-table-column>
				</el-table-column>
				
				<el-table-column  label="差值" >
					<template v-slot="scope">
						<div v-if="scope.row.Dvalue > 0" style="color: #FF5C5C;">
							<span class="spanArr arrUp" >{{scope.row.Dvalue}}</span>
							&nbsp;&nbsp;↑
						</div>
						<div v-if="scope.row.Dvalue < 0" style="color: green;">
							<span class="spanArr arrUp" >{{scope.row.Dvalue}}</span>
							&nbsp;&nbsp;↓
						</div>
						<div v-if="scope.row.Dvalue == 0" >
							<span class="spanArr arrUp" >{{scope.row.Dvalue}}</span>
						</div>
					</template>
				</el-table-column>
		
		
				<el-table-column fixed="right" label="操作"  width="180px" >
					
					<template v-slot="scope" >
						<button class="ucBtn ucBtn-edit ucBtn-green" @click="handleClickEdit(scope.row)"><i class="iImg"></i>详情</button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination
					@size-change="handleSizeChange"
					@current-change="handleCurrentChange"
					:current-page="currentPage"
					:page-size="pagesize" 
					layout="prev, pager, next"
					:total="total_count">   
			</el-pagination>
	</div>
	<ctms_contract_gcjd_list @closecallinfo="closecallinfo"  v-if="centerDialogVisiblegl" width="70%" ></ctms_contract_gcjd_list>
</template>
<script>
	import ctms_contract_gcjd_list from './ctms_contract_gcjd_list.vue';
	import {ElConfigProvider} from 'element-plus';
	import zhCn from 'element-plus/lib/locale/lang/zh-cn';
	export default {
		name: 'ZhProvider',
		components:{
			[ElConfigProvider.name]: ElConfigProvider
		},
		setup(){
			let locale = zhCn
			return{
				locale
			}
		},
		data: function() {
			return {
				centerDialogVisiblegl:false,
				id:null,
				department:[],
				cno:null,
				cname:"null",
				thisCreateTime:null,
				thisGcjd:null,
				lastCreateTime:null,
				lastGcjd:null,
				Dvalue:null,
				beginDate:null,
				endDate:null,
				deptId:null,
				stat:null,
				gid:null,
				dataList:[],
				currentPage:1, //初始页
				pagesize:10,    //    每页的数据
				total_count:0,
				shouldshow:false,
				putawayname:'收起',
				
			}
		},
		created() {
			// this.searchUpdateList();
			this.setDate();
			this.searchDepartment();
			this.fetchData();
			
		},
		methods: {
			handleClickEdit: function(data){
				var req = {};
				this.infoMod = "edit";
				// req.cmd = "searchDetail";
				// this.editId = data.id;
				// this.ctype = data.ctype;
				// this.cno = data.cno;
				this.editId = data.id;
				// this.ctype = data.ctype;
				// this.zthtId = data.zthtId;
				// this.gid = data.gid;
				console.log(this.ctype);
				this.centerDialogVisiblegl = true;
			},
			handleClickDelete: function(data){
				
			},
			handleClickAdd: function(){
				
			},
			handleClick: function(data){
				
			},
			handleClickQuery:function(){
				this.dataList=[]
				var req = {};
				req.cmd = "getGCJDUpdateList";
				if(this.deptId==''||this.deptId==null){
					req.deptId = '';
				}else{
					req.deptId = this.deptId.join(',');
				}
				req.beginDate = this.beginDate;
				req.endDate = this.endDate;
				req.gid = this.gid;
				req.cno =this.cno;
				req.start = (this.currentPage-1) * this.pagesize;
				req.count = this.pagesize;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result);
					this.dataList = res.datas.result.gcjdRecords;
					this.total_count = res.datas.result.total_count;
				}, null, this);	
			},
			exportGcjd:function(){
				
			},
			fetchData: function(){
				this.dataList=[]
				var req = {};
				req.cmd = "getGCJDUpdateList";
				req.start = (this.currentPage-1) * this.pagesize;
				req.count = this.pagesize;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result);
					this.dataList = res.datas.result.gcjdRecords;
					this.total_count = res.datas.result.total_count;
					// console.log(res.datas.result.gcjdRecords.stat);
				}, null, this);	
			},
			searchDepartment:function(){
				this.departments = [];
				var req = {};
				req.cmd = "searchDepartment";
				req.type=1;
				// req.id = id;
				this.$sknet.postJson(this.$skconf("iot.surl"), req, function(res) {
					console.log(res.datas.result.departments);
					this.departments = res.datas.result.departments;
				}, null, this);	
			},
			deleteData: function(id){
				
			},
			closecallinfo: function() {
				this.centerDialogVisiblegl=false;
			},
			searchMajors: function(){
				
			},
			getType:function(){
				
			},
			handleClickExport:function(){
				var req = {};
				req.cmd = "exportGcjdExcel";
				if(this.deptId==''||this.deptId==null){
					req.deptId = '';
				}else{
					req.deptId = this.deptId.join(',');
				}
				req.beginDate = this.beginDate;
				req.endDate = this.endDate;
				req.gid = this.gid;
				req.cno =this.cno;
				this.$sknet.download(this.$skconf("iot.surl"), req)
			},
			handleSizeChange: function (size) {
				this.pagesize = size;
				console.log(this.pagesize)  //每页下拉显示数据
			},
			handleCurrentChange: function(currentPage){
				this.currentPage = currentPage;
				this.fetchData();
				console.log(this.currentPage)  //点击第几页
			},
			putaway:function() {
				if(this.putawayname=='收起'){
					this.shouldshow=true;
					this.putawayname='打开';
				}else{
					this.shouldshow=false;
					this.putawayname='收起';
				}
			},
			setDate:function() {
				const y = new Date().getFullYear();
				const m = new Date().getMonth() + 1;
				const f = y + '-'+ m +'-01';
				const l = y + '-'+ m +'-31';
				this.beginDate = f;
				this.endDate=l;
			},
			closecallinfogl: function() {
				this.centerDialogVisiblegl = false;
				this.fetchData();
			},
		},
		watch: {
		},
		components: {
			ctms_contract_gcjd_list
		},
		mounted: function() {
			
		}
	}
</script>